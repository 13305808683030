import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate, Link,useLocation } from "react-router-dom";
import { TableContainer, Table, TableHead, TableHeader, TableBody, TableRow, TableCell, TableSelectRow, 
        TableSelectAll, TableToolbar, TableToolbarContent, TableToolbarSearch, Pagination, OverflowMenu, OverflowMenuItem, Button, Tag } from '@carbon/react';
import { TrashCan as Delete, View, Download, Column } from '@carbon/icons-react';		
import { useRowSelection, useSortInfo, useUniqueId } from '../../../_helpers/tables/table_hooks';
import useToastAlert from '../../../hooks/useToastAlert';
import TableLoader from '../../common/TableLoader';
import TableNoData from '../../common/TableNoData';
import { useSelector, useDispatch } from 'react-redux';
import { TABLE_SIZE, TABLE_SORT_DIRECTION } from '../../../_helpers/tables/table_misc';
import { Header as headers } from './Header';
import helpers from '../../../_helpers/common';
import { __trans, getData, makeRequest } from '../../../_helpers';

import DeleteConfirmation from '../../common/DeleteConfirmation';
import SendReminderConfirmation from './SendReminderConfirmation';
import NoFIleNotification from './NoFIleNotification';
import ViewRequest from './ViewRequest';

const hasSelection = true;

const List = () => {
	const location = useLocation(); // Get the current location object (URL)
	const { addError } = useToastAlert();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { contentLoading, reloaddata }     = useSelector((state) => state.common);
	const [sendreminder, setSendReminder]    = useState(false);
	const [deleteitem, setDeleteItem]        = useState(false);
	const [viewrequest, setViewRequest]      = useState(false);
	const [nofile, setNoFile]                = useState(false);
	const [selecteditem, setSelectedItem]    = useState('');
	const [rows, setRows]                    = useState([]);
	const [searchString, setSearchString]    = useState('');
	const [pagination, setPagination]        = useState({page : 1, limit: 15, total_pages: 0, total_item: 0});
	const [sortInfo, setSortInfo]            = useSortInfo({ columnId: '_id', direction: TABLE_SORT_DIRECTION.DESCENDING });
	const [setRowSelection] 				 = useRowSelection(rows, searchString, setRows);
	const selectedRowsCount                  = rows.filter(({ selected }) => selected).length;
	const selectedAll                        = selectedRowsCount > 0 && rows.length === selectedRowsCount;
	const hasBatchActions                    = hasSelection && selectedRowsCount > 0;
	const elementId                          = useUniqueId('delivered_list');
	const selectionAllName                   = !hasSelection ? undefined : `__custom-data-table_select-all_${elementId}`;
	const { columnId: sortColumnId, direction: sortDirection } = sortInfo;
	

	useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
		const action = searchParams.get('action'); // Get 'id' query param

		const itemId = searchParams.get('id'); // Get 'id' query param
        if (itemId && action) {
			setViewRequest(true); // Open the modal
			setSelectedItem(itemId)
        }
    }, [location]); 

	const getRequestList = async(type) => {
		var res = {};
		if(type === 'load'){
			res = await getData("firm/request-list", {type: 'delivered', page: pagination.page, limit: pagination.limit, search_string: searchString}, 'POST', addError, dispatch)
		}else{
			res = await makeRequest("firm/request-list", {type: 'delivered', page: pagination.page, limit: pagination.limit, search_string: searchString}, 'POST', addError);
		}
		
		if (res.type === "success") {
			setRows(res.data.docs);
			setPagination({...pagination, total_pages: Number(res.data.totalPages), total_item: Number(res.data.totalDocs)});
		}	
	}
	
	const handleGetData = useCallback(() => {
		getRequestList('load');
	}, []);

	useEffect(() => {
		handleGetData();
	}, [handleGetData])

	useEffect(() => {
		if(reloaddata){
			handleGetData();
		}
	}, [reloaddata])
	
	useEffect(() => {
		getRequestList('filter');
	}, [pagination.page, pagination.limit, searchString])

	const handleDeleteItem = (id) => {
		setSelectedItem(id);
		setDeleteItem(true);
	}

	const handleSendReminder = (id) => {
		setSelectedItem(id);
		setSendReminder(true);
	}
	
	const handleViewRequest = (id) => {
		setSelectedItem(id);
		setViewRequest(true);
	}

	const handleChangeSearchString = useCallback(({ target }) => {
		setSearchString(target.value);
	}, [setSearchString]);

	const handleChangeSelection = useCallback((event) => {
		const { currentTarget } = event;
		const row = currentTarget.closest('tr');
		if (row) {
			setRowSelection(row.dataset.rowId, currentTarget.checked);
		}
	}, [setRowSelection]);

	const handleChangeSelectionAll = useCallback((event) => {
		setRowSelection(undefined, event.currentTarget.checked);
	}, [setRowSelection]);

	const handleChangeSort = useCallback((event) => {
		const { currentTarget } = event;
		const { columnId, sortCycle, sortDirection: oldDirection, } = currentTarget.dataset;
		setSortInfo({ columnId, sortCycle, oldDirection });
	}, [setSortInfo]);

	/* const handleDeleteRows = useCallback(() => {
		
	}, []); */
	
	const handlePaginationChange = (e) => {
		//console.log('pagination event', e)
		setPagination({...pagination, page: e.page, limit: e.pageSize})
	}

	const processTableCell = (columnId, row, index) => {
		return helpers.processRequestData(row, columnId, TableCell, Tag);
	}
	
  /* eslint-disable no-script-url */
  return (
    <>
	{!contentLoading ?
    <TableContainer title={__trans('delivered_client_list_title')} description={__trans('delivered_client_list_info')}>
      <TableToolbar size="lg">
        <TableToolbarContent className="align-items-center">
          <TableToolbarSearch
				value={searchString}
				defaultExpanded={searchString.length > 0 ?? true}
				tabIndex={hasBatchActions ? -1 : 0}
				onChange={handleChangeSearchString}
          />
		  <Button size="sm" kind="ghost" hasIconOnly renderIcon={Delete} iconDescription={__trans('delete_button')} onClick={() => alert('TableToolbarAction - Delete')} />
		  <Button size="sm" kind="ghost" hasIconOnly renderIcon={Column} iconDescription={__trans('colomn_configration_button')} onClick={() => alert('Column')} />
        </TableToolbarContent>
      </TableToolbar>
      <Table size="sm" isSortable useZebraStyles={true}>
        <TableHead>
          <TableRow>
            {hasSelection && (
              <TableSelectAll
                id={`${elementId}--select-all`}
                checked={selectedAll}
                indeterminate={
                  selectedRowsCount > 0 && !selectedAll
                }
                ariaLabel="Select all rows"
                name={selectionAllName}
                onSelect={handleChangeSelectionAll}
              />
            )}
            {headers.map(({ id: columnId, sortCycle, title }) => {
              const sortDirectionForThisCell = sortCycle && (columnId === sortColumnId ? sortDirection : TABLE_SORT_DIRECTION.NONE);
              return (
                <TableHeader
                  key={columnId}
                  isSortable={Boolean(sortCycle)}
                  isSortHeader={sortCycle && columnId === sortColumnId}
                  sortDirection={sortDirectionForThisCell}
                  data-column-id={columnId}
                  data-sort-cycle={sortCycle}
                  data-sort-direction={sortDirectionForThisCell}
                  onClick={handleChangeSort}>
                  {title}
                </TableHeader>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ?
			rows.map((row, index) => {
            const { id: rowId, selected } = row;
            const selectionName = !hasSelection ? undefined : `__custom-data-table_${elementId}_${rowId}`;
			return (
              <TableRow
                key={rowId}
                isSelected={hasSelection && selected}
                data-row-id={rowId}>
                {hasSelection && (
                  <TableSelectRow
                    id={`${elementId}--select-${rowId}`}
                    checked={Boolean(selected)}
                    name={selectionName}
                    ariaLabel="Select row"
                    onSelect={handleChangeSelection}
                  />
                )}
                {headers.map(({ id: columnId }) => (
                  <>
                    {columnId !== 'actions' ?
                      <>
                      {processTableCell(columnId, row, index)}
                      </>
                      :
						<TableCell key={columnId}>
							<div className="d-flex align-items-center">
								<Button size="sm" kind="ghost" renderIcon={View} hasIconOnly iconDescription={__trans('view_detail_button')} onClick={() => handleViewRequest(row._id)}/>
								{row.download_zip_url !== '' ?
							<Button size="sm" kind="ghost" renderIcon={Download} hasIconOnly iconDescription={__trans('download_button')} onClick={() => setNoFile(true)} />

								:
								<Button size="sm" kind="ghost" renderIcon={Download} hasIconOnly iconDescription={__trans('download_button')} onClick={() => setNoFile(true)} />
								}
								<Button size="sm" kind="ghost" renderIcon={Delete} onClick={() => handleDeleteItem(row._id)} iconDescription={__trans('delete_button')} hasIconOnly />
								<OverflowMenu size="sm" flipped>
									<OverflowMenuItem itemText={__trans('edit_client_info_button')} onClick={() => navigate("/client-list?type=edit&id="+row.client_id._id)}/>
									<OverflowMenuItem itemText={__trans('send_reminder_button')} onClick={() => handleSendReminder(row._id)}/>
									<OverflowMenuItem itemText={__trans('share_button')} />
								</OverflowMenu>
							</div>
						</TableCell>
                    }
                  </>
                ))}
              </TableRow>
            );
          })
		   :
			<TableNoData headers={headers}/>
		  }
        </TableBody>
      </Table>
      <Pagination
		  backwardText="Previous page"
		  forwardText="Next page"
		  itemsPerPageText="Items per page:"
		  onChange={(e) => handlePaginationChange(e)}
		  page={pagination.page}
		  pageSize={pagination.limit}
          pageSizes={[5, 10, 15]}
		  size="md"
		  totalItems={pagination.total_item}
		/>
    </TableContainer>
	:
		<TableLoader {...helpers.tableLoaderProps(headers)}/>
	}
	{/* Delete Confirmation popup */}
	<DeleteConfirmation open={deleteitem} setOpen={setDeleteItem} module='client-request' id={selecteditem}/>
	{/* Send reminder popup */}
	<SendReminderConfirmation open={sendreminder} setOpen={setSendReminder} id={selecteditem}/>
	{/* View Request popup */}
	<ViewRequest open={viewrequest} setOpen={setViewRequest} id={selecteditem}/>
	{/* No File Notification popup */}
	<NoFIleNotification open={nofile} setOpen={setNoFile} />
    </>
  );
};

List.defaultProps = {
  collator: new Intl.Collator(),
  hasSelection: false,
  pageSize: 5,
  size: TABLE_SIZE.REGULAR,
  start: 0,
};

export default List;
