import React, { useState, useEffect } from 'react';
import { Form, TextInput, Select, SelectItem, Checkbox, Button, InlineLoading } from '@carbon/react';
import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import AuthLayout from "../../layout/AuthLayout";
//import ServerError from "../common/ServerError";
import useToastAlert from '../../../hooks/useToastAlert';
import helpers from '../../../_helpers/common';
import { __trans, makeRequest } from '../../../_helpers';
import { GetCountries } from "../../../_helpers/select-list";


const Register = () => {
	const { addError } = useToastAlert();
	const token  = useParams();
	const { loading } = useSelector((state) => state.common);
    const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isChecked, setIsChecked] = useState(false)
	const [countries, setCountries] = useState([]);
	const [selectedcountry, setSelectedCountries] = useState({});
	const API_ENDPOINT = process.env.REACT_APP_ENDPOINT;
	const [form, setForm] = useState({ first_name: "", first_name_error: "", last_name: "", last_name_error: "", email: '', email_error: '', phone_no: '', phone_no_error: '', country: '', country_error: '', company_name: '', company_name_error: '', password: '', password_error: '',  confirm_password: '', confirm_password_error: '', agree_term: '', agree_term_error: '' });
	
	const checkVerifyToken = async() => {
		const response = await axios.post(`${(API_ENDPOINT)}users/verify-firm-invitation-token`, { token: token.token });
		try {
				if (response.data.type !== "success") {
				navigate("/login");
				} else {
				getCountryData();
				}
			} catch (error) {
				addError(error);
			}
		// await makeRequest("users/verify-firm-invitation-token", {token : token.token}, 'POST', addError)
		// 	.then((res) => {
		// 		if (res.type !== "success") {
		// 			console.log('sdfsdfsdf')
		// 			navigate("/login")
		// 		}else{
		// 			console.log('112121212')
		// 			getCountryData();
		// 		}
				
		// 	}).catch((error) => {
				
		// 	});
	}
	
	useEffect(() => {
		checkVerifyToken();
		getCountryData();
	}, [])
	
	const getCountryData = async() => {
		await GetCountries().then((data) => {
			setCountries(data);
		});
	}
	
	const phoneNoChanged = (value, data, event, formattedValue) => {
		setForm({...form, phone_no: formattedValue});
	}
	
	const valueChanged = (e) => {
		const { name, value } = e.target;
		let newEdit = { ...form };
		newEdit[name] = value;
		newEdit[name + '_error'] = '';
		setForm(newEdit);
		if(name === 'country'){
			selectedCountryInfo(value);
		}
	}
	
	const selectedCountryInfo = (country_id) => {
		var selected = countries.filter((item) => {return Number(item.id) === Number(country_id)});
		if(selected.length > 0){
			setSelectedCountries(selected[0])
		}
	}

	const validateForm = () => {
		let newEdit = { ...form };
		let validateData = true;
		if (helpers.isEmpty(form.first_name)) {
			newEdit.first_name_error = __trans('error_msg.first_name_error');
			validateData = false;
		} else {
			newEdit.first_name_error = '';
		}
		if (helpers.isEmpty(form.last_name)) {	
			newEdit.last_name_error = __trans('error_msg.last_name_error');
			validateData = false;
		} else {
			newEdit.last_name_error = '';
		}
		if (helpers.isEmpty(form.email)) {	
			newEdit.email_error = __trans('error_msg.email_error');
			validateData = false;
		} else {
			newEdit.email_error = '';
			if (!helpers.isValidEmail(form.email)) {
				newEdit.email_error = __trans('error_msg.email_invalid');
				validateData = false;
			}
		}
		if (helpers.isEmpty(form.phone_no)) {		
			newEdit.phone_no_error = __trans('error_msg.phone_no_error');
			validateData = false;
		} else {
			newEdit.phone_no_error = '';
			if (!helpers.validatePhoneNumber(form.phone_no)) {
				newEdit.phone_no_error = __trans('error_msg.phone_no_invalid');
				validateData = false;
			}
		}
		
		if (helpers.isEmpty(form.country)) {		
			newEdit.country_error = __trans('error_msg.field_required_error');
			validateData = false;
		} else {
			newEdit.country_error = '';
		}
		
		if (helpers.isEmpty(form.company_name)) {		
			newEdit.company_name_error = __trans('error_msg.company_name_error');
			validateData = false;
		} else {
			newEdit.company_name_error = '';
		}
		if (helpers.isEmpty(form.password)) {	
			newEdit.password_error = __trans('error_msg.password_empty');
			validateData = false;
		} else {
			let msg = helpers.isValidFormatForPassword(form.password);
			newEdit.password_error = '';
			if (msg !== '') {
				newEdit.password_error = msg;
				validateData = false;
			}
		}
		if (helpers.isEmpty(form.confirm_password)) {	
			newEdit.confirm_password_error = __trans('error_msg.password_empty');
			validateData = false;
		} else {
			newEdit.confirm_password_error = '';
			let msg = helpers.isValidFormatForPassword(form.confirm_password);
			if (msg !== '') {
				newEdit.confirm_password_error = msg;
				validateData = false;
			} else {
				if (form.confirm_password !== form.password) {
					newEdit.confirm_password_error = __trans('error_msg.confirm_pass_match_paas');
					validateData = false;
				}
			}
		}
		if (!isChecked) {
			newEdit.agree_term_error = __trans('error_msg.agree_term_error');
			validateData = false;
		} else {
			newEdit.agree_term_error = '';
		}
		setForm(newEdit);
		return validateData;
	}
	
	const handleSubmit = (e) => {
		//Before submit validate form
		if (validateForm()) {
			submitForm({ first_name: form.first_name, last_name: form.last_name, email: form.email, country: form.country, phone_no: form.phone_no, company_name: form.company_name, password: form.password, agree_term: isChecked === true ? 1 : 0, token : token.token })
		}
	}

	const submitForm = async (formData) => {
		dispatch(callCommonAction({ loading: true }));
		await makeRequest("users/register", formData, 'POST', addError)
			.then((res) => {
				dispatch(callCommonAction({ loading: false }));
				if (res.type === "success") {
					localStorage.setItem('token', res.jwttoken);
					localStorage.setItem('user', JSON.stringify(res.data));
					localStorage.setItem('firm', JSON.stringify(res.firm));
					navigate("/settings")
				} 
			}).catch((error) => {
				dispatch(callCommonAction({ loading: false }));
			});
	}
	
	const getSelectedCountryCode = () => {
		if(selectedcountry.iso2){
			return selectedcountry.iso2.toLowerCase();
		}
		return 'us';
	}
	
	return (
		<AuthLayout>
			<Form className="signup-form mb-3">
				<div className="form-heading mb-5 pt-3">
					<h3 className="mb-3">{__trans('singup_page_title')} </h3>
					 <p>{__trans('singup_page_text')} </p> 
				</div>
				<div className="row mb-4">
					<div className='col'>
						<TextInput type="text" name="first_name" id="first_name" onChange={valueChanged}
							labelText={__trans('forms.first_name')}
							placeholder={__trans('forms.first_name_placeholder')}
							invalid={form.first_name_error !== '' ? true : false}
							invalidText={form.first_name_error}
						/>
					</div>
					<div className='col'>
						<TextInput type="text" name="last_name" id="last_name" onChange={valueChanged}
							labelText={__trans('forms.last_name')}
							placeholder={__trans('forms.last_name_placeholder')}
							invalid={form.last_name_error !== '' ? true : false}
							invalidText={form.last_name_error}
						/>
					</div>
				</div>
				<div className="mb-4">
					<TextInput type="email" name="email" id="email" onChange={valueChanged}
						labelText={__trans('forms.email_label')}
						placeholder={__trans('forms.email_placeholder')}
						invalid={form.email_error !== '' ? true : false}
						invalidText={form.email_error}
					/>
				</div>
				{/*<div className="mb-4">
					<TextInput type="text" name="phone_no" id="phone_no" onChange={valueChanged}
						labelText={__trans('forms.phone_no_label')}
						placeholder={__trans('forms.phone_no_placeholder')}
						invalid={form.phone_no_error !== '' ? true : false}
						invalidText={form.phone_no_error}
					/>
				</div>*/}
				<div className="mb-4">
					<Select	name="country" id="country"
						value={form.country}
						onChange={valueChanged}
						labelText={__trans('forms.country_label')}
						placeholder={__trans('forms.country_placeholder')}
						invalid={form.country_error !== '' ? true : false}
						invalidText={form.country_error}
					>
						<SelectItem value="" text="Select" />
						{countries.length > 0 ? countries.map((item, index) => (
							<SelectItem value={item.id} text={item.name} />
							))
						:''
						}
					</Select>
				</div>
				<div className="col-12 mb-4">
					<div className="cds--form-item cds--text-input-wrapper">
						<div className="cds--text-input__label-wrapper">
							<label labelfor="phone_no" className="cds--label" dir="auto">
								{__trans('forms.phone_no_label')}
							</label>
						</div>
						<div className="cds--text-input__field-outer-wrapper invert">
							<div className="cds--text-input__field-wrapper" data-invalid="true">
								<PhoneInput className="w-100"
									  country={getSelectedCountryCode()}
									  enableSearch={true}
									  disableSearchIcon={true}
									  value={form.phone_no}
									  onChange={(value, country, e, formattedValue) => phoneNoChanged(value, country, e, formattedValue)}
									/>
							</div>
							<div className="cds--form-requirement" id="phone_no-error-msg" dir="auto">{form.phone_no_error}</div>
						</div>
					</div>
				</div>
				<div className="mb-4">
					<TextInput type="text" name="company_name" id="company_name" onChange={valueChanged}
						labelText={__trans('forms.company_name_label')}
						placeholder={__trans('forms.company_name_placeholder')}
						invalid={form.company_name_error !== '' ? true : false}
						invalidText={form.company_name_error}
					/>
				</div>
				<div className="row mb-4">
					<div className='col'>
					<TextInput type="password" name="password" id="password" onChange={valueChanged}
						labelText={__trans('forms.password_label')}
						placeholder={__trans('forms.password_placeholder')}
						invalid={form.password_error !== '' ? true : false}
						invalidText={form.password_error}
					/>
					</div>
					<div className='col'>
					<TextInput type="password" name="confirm_password" id="confirm_password" onChange={valueChanged}
						labelText={__trans('forms.confirm_password_label')}
						placeholder={__trans('forms.confirm_password_placeholder')}
						invalid={form.confirm_password_error !== '' ? true : false}
						invalidText={form.confirm_password_error}
					/>
					</div>
				</div>
				<div className="d-flex justify-content-between">
					<div className="d-flex mb-5">
						<Checkbox name="agree_term" id="agree_term" 
							onChange={(_, { checked }) => setIsChecked(checked)}
							labelText={<span>{__trans('agree_terms_text')} <Link to="/term-and-conditions" target={"_blank"}>{__trans('terms_conditions')}</Link></span>} 
							invalid={form.agree_term_error !== '' ? true : false}
							invalidText={form.agree_term_error}
							 />
					</div>
				</div>
				<div className="mt-4 mb-4">
					{!loading ?
						<Button className="btn btn-primary" onClick={handleSubmit} size="md">{__trans('forms.register_submit_button')}</Button>
						:
						<Button kind="secondary" size="md" as="div" role="button">
							<InlineLoading className="text-info" style={{ marginLeft: '1rem' }} description="Wait..." status="active" aria-live="off" />
						</Button>
					}
				</div>
				<div className='mb-2'>&nbsp;</div>
				<div className="text-center mb-2">
					<p className="mb-0 text-muted">{__trans('already_have_account_text')} <Link to="/login" className="text-decoration-none text-primary"><strong>{__trans('login_link')}</strong></Link></p>
				</div>
			</Form>
		</AuthLayout>
	)
}

export default Register
