import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, InlineNotification } from '@carbon/react';
import { Restart } from '@carbon/icons-react';
import useToastAlert from '../../../hooks/useToastAlert'
import { __trans, makeRequest } from '../../../_helpers';
import { DocusealBuilder } from '@docuseal/react'

const PrepareDocusealContract = ({ open, setOpen, form, setForm, setNextStep }) => {

	const { addError } = useToastAlert();
	const navigate = useNavigate();
	const [token, setToken] = useState('');
	const [sign, setSign] = useState('');

	const [submitcontrol, setSubmitControle] = useState({ status: 'inactive', description: __trans('submit_button') });

	const resetSubmitControlStatus = () => {
		setSubmitControle({ ...submitcontrol, status: 'inactive', description: __trans('submit_button') })
	};

	useEffect(() => {
		if (open) {
			const timer = setTimeout(() => {
				getTokenData();
			}, 5000);
			return () => clearTimeout(timer);
		}
	}, [open]);

	const getTokenData = async () => {
		try {
			const res = await makeRequest("firm/get_docuseal_builder_token/" + form.client_request_id, {}, 'POST', addError);

			if (res.type === "success") {
				setToken(res.token);
			}
		} catch (error) {
			console.error("Error while fetching token data:", error);
		}
	};


	useEffect(() => {
		if (open) {
			getTokenData();
		}
	}, [open]);

	const saveAndClose = () => {
		navigate('/clients/in-progress-list');
	}



	const handleCancel = () => {
		navigate('/clients/in-progress-list');
	}

	const savePreparedDocument = async (e) => {
		console.log(e?.fields?.length, "eand its vakue")
		setSign(e?.fields)
		if (e?.fields?.length === 0) {
			addError('Signature is required');
			return;
		}
		await makeRequest("firm/save-docuseal-sign-form/" + form.client_request_id, { sign_slug: e.slug, form_info: JSON.stringify(e) }, 'POST', addError)
			.then((res) => {
				if (res.type === "success") {

				}
			});
	}

	const handleSubmit = () => {
		if (sign?.length === 0) {  // Check if signature is missing before submitting
			addError('First save your signature document'); // Show error message
			return; // Prevent proceeding to next step if signature is not provided
		}

		setNextStep(5);
	}


	return (
		<Modal className="header-border two-buttons arrow-icon" size="lg" open={open} onRequestClose={() => handleCancel()}
			modalLabel=""
			modalHeading={__trans('prepare_document_pandadoc')}
			preventCloseOnClickOutside={true}
			primaryButtonText={__trans('continue_button')}
			secondaryButtonText={__trans('save_and_close_button')}
			onSecondarySubmit={() => saveAndClose()}
			onRequestSubmit={() => handleSubmit()}
			loadingStatus={submitcontrol.status}
			loadingDescription={submitcontrol.description}
			onLoadingSuccess={resetSubmitControlStatus}
		>
			<div className='container'>
				<div className="row">
					<div className="d-flex justify-content-between">
						<InlineNotification
							hideCloseButton={true}
							kind="info"
							subtitle={__trans('prepare_contract_noti_info')}
							title={__trans('prepare_contract_noti_title')}
						/>
						<div>
							<button onClick={() => getTokenData()} type="button" className="btn btn-sm cstm-btn-small btn-outline-secondary">
								{__trans('refresh_button')} <Restart />
							</button>
						</div>
					</div>
				</div>
				<div className='row'>
					{token !== "" ?
						<DocusealBuilder token={token}
							withSendButton={false}
							withUploadButton={false}
							withSignYourselfButton={false}
							autosave={false}
							onSave={(e) => savePreparedDocument(e)}
						/>
						: ''
					}
				</div>
			</div>
		</Modal>
	);
};

export default PrepareDocusealContract