import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Modal, Form, Stack, Search, TextInput, Select, SelectItem, Loading, ContainedList, ContainedListItem } from '@carbon/react';
import { Edit, Restart } from '@carbon/icons-react';
import { useSelector, useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import useToastAlert from '../../../hooks/useToastAlert';
import helpers from '../../../_helpers/common';
import { __trans, makeRequest } from '../../../_helpers';
import { GetCountries } from "../../../_helpers/select-list";

const SelectClient = ({ open, setOpen, form, setForm, setNextStep }) => {
	const { addError } = useToastAlert();
	const navigate = useNavigate();
	const { contentLoading } = useSelector((state) => state.common);
    const dispatch = useDispatch();
	const [clients, setClients] = useState([]);
	const [countries, setCountries] = useState([]);
	const [clientform, setClientForm] = useState({ id: '', client_id: '', first_name: "", last_name: "", email: '', company_name: '', cuil_cuit: '', cuil_cuit_number: '', phone_country_code: '', phone_no: '', extension: '', status: 1});
	const [errors, setErrors] = useState({ first_name: "", last_name: "", email: '', company_name: '', cuil_cuit: '', cuil_cuit_number: '', phone_country_code: '', phone_no: '', extension: '', status: 1});
	const [submitcontrol, setSubmitControle] = useState({ status: 'inactive', description: __trans('submit_button') });
	const resetSubmitControlStatus = () => {
		setSubmitControle({...submitcontrol, status: 'inactive', description: __trans('submit_button')})
	};
	
	/***Get User Data In Edit Mode**********************/
	const getUserData = async() => {
		try {
			dispatch(callCommonAction({ contentLoading: true }));
			const res = await makeRequest(`users/detail/${form.client_id}`, 'GET');
			dispatch(callCommonAction({ contentLoading: false }));
			if (res.type === 'success') {
				setClientForm({ ...clientform, id: res.data._id, client_id: res.data.client_id, first_name: res.data.first_name, last_name: res.data.last_name, email: res.data.email, phone_no: res.data.phone_no, status: res.data.status, company_name: res.data.client_profile_id.company_name, cuil_cuit: res.data.client_profile_id.cuil_cuit, cuil_cuit_number: res.data.client_profile_id.cuil_cuit_number, phone_country_code: res.data.client_profile_id.phone_country_code, extension: res.data.extension});
				if(form.email === ''){
					setForm({...form, email: res.data.email });
				}
			}
		} catch (error) {
			dispatch(callCommonAction({ contentLoading: false }));
			addError(error.message, 'error')
		}
	}
	
	/***Get Available Country List**********************/
	const getCountryData = async() => {
		await GetCountries().then((data) => {
			setCountries(data);
		});
	}
	  
	useEffect(() => {
		if(open){
			getCountryData();
			if(form.client_id !== ''){
				getUserData();
			}
		}
	}, [open])
	
	/***Handle Form Value Changed**********************/
	const valueChanged = (e) => {
		const { name, value } = e.target;
		let newEdit = { ...clientform };
		newEdit[name] = value;
		setClientForm(newEdit);
	}
	
	const phoneNoChanged = (value, data, event, formattedValue) => {
		setClientForm({...clientform, phone_no: formattedValue});
	}
	
	/***Validate Client Selected**********************/
	const validateForm = (type) => {
		let newEdit = { ...form };
		let validateData = true;
		if (form.client_id === '') {
			if(clientform.email === '' && clientform.first_name === '' && clientform.last_name === ''){
				newEdit.client_id_error = __trans('error_msg.select_client_error');
				validateData = false;
			}else{
				createNewClient(type);
				newEdit.client_id_error = '';
				validateData = false;
			}
		} else {
			newEdit.client_id_error = '';
		}
		
		setForm(newEdit);
		return validateData;
	}
	
	const validateClientForm = () => {
		let newEdit = { ...errors };
		let validateData = true;
		if (clientform.first_name === '') {
			newEdit.first_name = __trans('error_msg.first_name_error');
			validateData = false;
		} else {
			newEdit.first_name = '';
		}
		if (clientform.last_name === '') {
			newEdit.last_name = __trans('error_msg.last_name_error');
			validateData = false;
		} else {
			newEdit.last_name = '';
		}
		if (clientform.email === '') {
			newEdit.email = __trans('error_msg.email_error');
			validateData = false;
		} else {
			newEdit.email = '';
			if (!helpers.isValidEmail(clientform.email)) {
				newEdit.email = __trans('error_msg.email_invalid');
				validateData = false;
			}
		}
		if (clientform.phone_no === '') {
			newEdit.phone_no = __trans('error_msg.phone_no_error');
			validateData = false;
		} else {
			newEdit.phone_no = '';
			if (clientform.phone_no.length < 9) {
				newEdit.phone_no = __trans('error_msg.phone_no_invalid');
				validateData = false;
			}
			/*if (!helpers.validateNumber(clientform.phone_no)) {
				newEdit.phone_no = __trans('error_msg.phone_no_invalid');
				validateData = false;
			}*/
		}
		
		// if (clientform.company_name === '') {
		// 	newEdit.company_name = __trans('error_msg.company_name_error');
		// 	validateData = false;
		// } else {
		// 	newEdit.company_name = '';
		// }
		
		if (clientform.cuil_cuit === '') {
			newEdit.cuil_cuit = __trans('error_msg.field_required_error');
			validateData = false;
		} else {
			newEdit.cuil_cuit = '';
		}
		
		if (clientform.cuil_cuit_number === '') {
			newEdit.cuil_cuit_number = __trans('error_msg.field_required_error');
			validateData = false;
		} else {
			newEdit.cuil_cuit_number = '';
		}
		
		setErrors(newEdit);
		return validateData;
	}
	
	/***Submit Form**********************/
	const handleSubmit = (e) => {
		//Before submit validate form
		if (validateForm('continue')) {
			submitForm();
		}
	}
	
	/***Submit Form To Next Step**********************/
	const submitForm = () => {
		setNextStep(3);
	}
	
	/***Save and Close Request**********************/
	const saveAndClose = async(new_client_id) => {
		if (new_client_id === ''){
			if(!validateForm('save_close')) {
				return false;
			}
		}
		//if (new_client_id === '' && validateForm('save_close')) {
			var formData = new FormData();
			formData.append(`package_id`, form.package_id)
			formData.append(`client_id`, new_client_id !== '' ? new_client_id : form.client_id)
			formData.append(`sign_document`, form.sign_document)
			formData.append(`question_template_id`, form.question_template_id)
			formData.append(`documents_template_id`, form.documents_template_id)
			
			setSubmitControle({...submitcontrol, status: 'active'})
			let end_url = form.client_request_id === '' ? 'firm/create-client-request' : 'firm/update-client-request/'+form.client_request_id;
			await makeRequest(end_url, formData, 'POST', addError)
				.then((res) => {
					setSubmitControle({...submitcontrol, status: 'finished'})
					if (res.type === "success") {
						navigate('/clients/in-progress-list');
					} 
				})
				.catch((error) => {
					setSubmitControle({...submitcontrol, status: 'finished'})
				});
		//}
	}

	/***Search Client**********************/
	const searchFirmClient = async(string) => {
		if(string !== ''){
			await makeRequest("users/firm-client-list-data", {search_string: string}, 'POST', addError)
				.then((res) => {
					if (res.type === "success") {
						setClients(res.data.docs);
					} 
				})
				.catch((error) => {
					
				});
		 }else{
			 setClients([]);
		 }
	}
	
	/***Select Client From Search Dropdown**********************/
	const selectClient = (id) => {
		//console.log('id', id)
		var new_array = clients.filter(item => item._id === id);
		//console.log('new_array[0]', new_array[0])
		if(new_array.length > 0){
			//console.log('new_array[0]', new_array[0])
			setClientForm({ ...clientform, id: new_array[0]._id, client_id: new_array[0].client_id, first_name: new_array[0].first_name, last_name: new_array[0].last_name, email: new_array[0].email, phone_no: new_array[0].phone_no, status: new_array[0].status, company_name: new_array[0].client_profile_id.company_name, cuil_cuit: new_array[0].client_profile_id.cuil_cuit, cuil_cuit_number: new_array[0].client_profile_id.cuil_cuit_number, phone_country_code: new_array[0].client_profile_id.phone_country_code, extension: new_array[0].extension});
			let email = new_array[0].email;
			setForm({...form, client_id: id, email: email });
		}
		setClients([]);
	}
	
	/***Create New Client**********************/
	const createNewClient = async (type) => {
		if(validateClientForm()){
			var formData = { first_name: clientform.first_name, last_name: clientform.last_name, email: clientform.email, company_name: clientform.company_name, cuil_cuit: clientform.cuil_cuit, cuil_cuit_number: clientform.cuil_cuit_number, phone_country_code: clientform.phone_country_code, phone_no: clientform.phone_no, extension: clientform.extension, status: 1}
			setSubmitControle({...submitcontrol, status: 'active'})
			await makeRequest('users/add-client', formData, 'POST', addError)
				.then((res) => {
					//console.log('create new client res', res.data._id);
					if (res.type === "success") {
						//set client id and email in form state
						let newEdit = { ...form };
						newEdit.client_id = res.data._id;
						newEdit.email     = res.data.email;
						setForm(newEdit);
						if(type === 'continue'){
							setSubmitControle({ ...submitcontrol, status: 'finished' });
							setNextStep(3);	
						}else{
							saveAndClose(res.data._id);
						}
					}else{
						setSubmitControle({ ...submitcontrol, status: 'finished' })
					} 
				}).catch((error) => {
					setSubmitControle({ ...submitcontrol, status: 'finished' })
				});
		}
	}
	
	/***Create Client Edit Route**********************/
	const getEditRoute = () => {
		return "/client-list?type=edit&id="+clientform.id;
	}
	
	/***Handle Cancel Button**********************/
	const handleCancel = () => {
		navigate('/clients/in-progress-list');
	}
	
	return (
		<Modal className="header-border two-buttons add-client-modal arrow-icon" open={open} onRequestClose={() => handleCancel()} 
			modalLabel="" 
			modalHeading={__trans('select_client_title')}
			preventCloseOnClickOutside={true}
			primaryButtonText={__trans('continue_button')} 
			secondaryButtonText={__trans('save_and_close_button')} 
			onSecondarySubmit={() => saveAndClose('')}
			onRequestSubmit={() => handleSubmit() }
			loadingStatus={submitcontrol.status} 
			loadingDescription={submitcontrol.description} 
			onLoadingSuccess={resetSubmitControlStatus}
		>
		{form.client_id !== '' ? 
			<div className="row mb-1">
				<div className="col-md-12 d-flex gap-2 justify-content-end">
					<Link to={getEditRoute()} target="_blank" className="btn btn-outline-primary btn-sm cstm-btn-small">
						{__trans('edit_client_button')} <Edit />
					</Link>
					<button onClick={() => getUserData()} type="button" className="btn btn-sm cstm-btn-small btn-outline-secondary">
						{__trans('refresh_button')} <Restart />
					</button>
				</div>
			</div>
			: ''
		}
		<div className="row mb-4">
			<div className="col-md-6 px-1 position-relative hide-contained-list__header">
				<div className="cds--text-input__label-wrapper">
					<label className="cds--label" dir="auto">{__trans('forms.search_client_label')}</label>
				</div>
				<Search size="md" id="search-1"
					placeholder={__trans('forms.search_placeholder')} 
					labelText={__trans('forms.search_client_label')}
					closeButtonLabelText={__trans('clear_search_button')}  
					onChange={(e) => searchFirmClient(e.target.value)} 
				/>
				{clients.length > 0 ?
					<ContainedList size="sm" label=""  className="bg-body border m-0" 
						style={{
							position: 'absolute',
							left: '4px',
							right: '4px',
							zIndex: '10',
							height: '150px',
							overflowY: 'auto',
						}}>
						{clients.map((item) => (
							<ContainedListItem key={item._id} className="" onClick={() => selectClient(item._id)}>{item.fullname}</ContainedListItem>
						))}
					</ContainedList >
					:""
				}
			</div>
		</div>
		{!contentLoading ?
			<Form aria-label="create/update client form">
				<Stack>
					<div className=''>
						<div className="row mb-2">
							{form.client_request_id !== '' ?
								<div className="col-md-2 px-1">
									<TextInput type="text" name="client_id" id="client_id"
										disabled={true}
										onChange={valueChanged}
										value={clientform.client_id}
										labelText={__trans('forms.client_id_label')}
									/>
								</div>
								:''
							}
							<div className="col-md-5 px-1">
								<TextInput type="text" name="first_name" id="first_name"
									readOnly={form.client_id !== '' ? true : false}
									onChange={valueChanged}
									value={clientform.first_name}
									labelText={__trans('forms.first_name')}
									placeholder={__trans('forms.first_name_placeholder')}
									invalid={errors.first_name !== '' ? true : false}
									invalidText={errors.first_name}
								/>
							</div>
							<div className="col-md-5 px-1">
								<TextInput type="text" name="last_name" id="last_name"
								    readOnly={form.client_id !== '' ? true : false}
									onChange={valueChanged}
									value={clientform.last_name}
									labelText={__trans('forms.last_name')}
									placeholder={__trans('forms.last_name_placeholder')}
									invalid={errors.last_name !== '' ? true : false}
									invalidText={errors.last_name}
								/>
							</div>
						</div>
						<div className="row mb-2">
							<div className="col-md-5 px-1">
								<TextInput type="text" name="company_name" id="company_name" 
									readOnly={form.client_id !== '' ? true : false}
									onChange={valueChanged}
									value={clientform.company_name}
									labelText={__trans('forms.company_name_label')}
									placeholder={__trans('forms.company_name_placeholder')}
								/>
							</div>
							<div className="col-md-7 px-1">
								<TextInput type="email" name="email" id="firm_email"
									readOnly={form.client_id !== '' ? true : false}
									onChange={valueChanged}
									value={clientform.email}
									labelText={__trans('forms.email_label')}
									placeholder={__trans('forms.email_placeholder')}
									invalid={errors.email !== '' ? true : false}
									invalidText={errors.email}
								/>
							</div>
						</div>
						<div className="row mb-2">
							<div className="col-md-2 px-1">
								<Select	name="cuil_cuit" id="cuil_cuit"
									readOnly={form.client_id !== '' ? true : false}
									onChange={valueChanged}
									value={clientform.cuil_cuit}
									labelText={__trans('forms.cuil_cuit_label')}
									placeholder={__trans('forms.cuil_cuit_placeholder')}
									invalid={errors.cuil_cuit !== '' ? true : false}
									invalidText={errors.cuil_cuit}
								>
									<SelectItem value="" text="Select" />
									<SelectItem value="CUIL" text="CUIL" />
									<SelectItem value="CUIT" text="CUIT" />
								</Select>
							</div>
							<div className="col-md-3 px-1">
								<TextInput type="text" name="cuil_cuit_number" id="cuil_cuit_number" 
									readOnly={form.client_id !== '' ? true : false}
									onChange={valueChanged}
									value={clientform.cuil_cuit_number}
									labelText={__trans('forms.cuil_cuit_number_label')}
									placeholder={__trans('forms.cuil_cuit_number_placeholder')}
									invalid={errors.cuil_cuit_number !== '' ? true : false}
									invalidText={errors.cuil_cuit_number}
								/>
							</div>
							<div className="col-md-5 px-1">
								<div className="cds--form-item cds--text-input-wrapper">
									<div className="cds--text-input__label-wrapper">
										<label labelfor="phone_no" className="cds--label" dir="auto">
											{__trans('forms.phone_no_label')}
										</label>
									</div>
									<div className="cds--text-input__field-outer-wrapper">
										<div className="cds--text-input__field-wrapper" data-invalid="true">
											<PhoneInput className=""
												  country={'us'}
												  enableSearch={true}
												  disableSearchIcon={true}
												  value={clientform.phone_no}
												  onChange={(value, country, e, formattedValue) => phoneNoChanged(value, country, e, formattedValue)}
												/>
										</div>
										<div className="cds--form-requirement" id="phone_no-error-msg" dir="auto">{errors.phone_no}</div>
									</div>
								</div>
							</div>
							<div className="col-md-2 px-1">
								<TextInput type="text" name="extension" id="extension"
									readOnly={form.client_id !== '' ? true : false}
									onChange={valueChanged}
									value={clientform.extension}
									labelText={__trans('forms.extension_label')}
									placeholder={__trans('forms.extension_placeholder')}
									invalid={errors.extension !== '' ? true : false}
									invalidText={errors.extension}
								/>
							</div>
						</div>
                    </div>
					{form.client_id_error !== '' ? <span className="text-danger">{ form.client_id_error }</span> : ''}
				</Stack>
			</Form>
			:
			<div className='d-flex justify-content-center'>
				<Loading className='text-center' withOverlay={false} />
			</div>
		}
	</Modal>
	)
}

export default SelectClient
