import React, { useState,useEffect } from 'react';
import { Modal, Button } from '@carbon/react';
import useToastAlert from '../../../../hooks/useToastAlert'
import { DocusealForm } from '@docuseal/react'
import { __trans, makeRequest, userDetails } from '../../../../_helpers'

const SignContract = ({ open, setOpen, contract, requestdata ,onComplete }) => {
	console.log(open,"ccccccccccccccc")
	console.log(requestdata,"requestdata")

	//console.log('contract', contract)
	const { addError } = useToastAlert();
	var user_email = userDetails('email');
	const [showCompleteButton, setShowCompleteButton] = useState(false); // Track whether to show the button
	const [data, setData] = useState(); // Track whether to show the button
	const [dataSign, setDataSign] = useState(); // Track whether to show the button



	
	const formLoaded = (data) => {
		console.log('formLoaded data', data.template === '')
		setData(data)
	}

	const formCompleted = async (data) => {
		console.log(data,'data is numer 11')
		setDataSign(data)
		await makeRequest("firm/update-document-signatured/" + requestdata._id, {}, 'POST', addError)
			.then((res) => {
				if (res.type === "success") {
					setShowCompleteButton(true);
					onComplete()
				}
			})
			.catch((error) => {
				console.log('catch error', error.message);
			});
	}
	const handleHeaderButtonClick = () => {
		// Optionally handle additional logic when the header button is clicked
		console.log('Header button clicked');
		setOpen(false);
		setShowCompleteButton(false)
	};

	//console.log('contract src', `https://docuseal.co/d/${requestdata.docuseal_sign_slug}`)

	return (
		<Modal
			className="header-border header-sign"
			size="lg"
			open={open}
			onRequestClose={() => setOpen(false)}
			modalLabel=""
			modalHeading={
				<div className="modal-header">
					<span>{__trans('sign_document')}</span>
					{showCompleteButton && (
						<button className="header-button" onClick={handleHeaderButtonClick}>
							Done
						</button>
					)}
				</div>
			}
			passiveModal={true}
			preventCloseOnClickOutside={true}
		>
			<div>
				{open ?
					<DocusealForm
						src={`https://docuseal.co/d/${requestdata.docuseal_sign_slug}`}
						email={user_email}
						onInit={() => { console.log("Loaded") }}
						onLoad={(data) => { formLoaded(data) }}
						onComplete={(data) => formCompleted(data)}
					/>
					: ''
				}
			</div>
		</Modal>
	);
};

export default SignContract;