import React, { useState, useEffect } from 'react';
import moment from 'moment'
import { Table, TableHead, TableBody, TableRow, TableHeader, TableCell, Button } from 'carbon-components-react';
import { DocumentBlank, Download, Checkmark, Delete } from '@carbon/icons-react';

import { useDispatch } from 'react-redux';
import useToastAlert from '../../../../hooks/useToastAlert'
import { __trans, getData, makeRequest } from '../../../../_helpers';
import checkIcon from './check.png'
import iconCross from './icon.png'
import DeleteConfirmation from '../../../common/DeleteConfirmation';

import { callCommonAction } from '../../../../redux/Common/CommonReducer';

const UploadDocument = ({ request, show }) => {
	const { addError } = useToastAlert();
	const dispatch = useDispatch();
	const [docData, setDocData] = useState({});
	const [uploadedDoc, setUploadedDoc] = useState({});
	const [selecteditem, setSelectedItem] = useState('');

	const [deleteitem, setDeleteItem] = useState(false);

	const getDocumentList = async () => {
		await getData("template/get-client-template-document/" + request?._id, {}, 'POST', addError, dispatch)
			.then((res) => {
				//console.log('res', res)
				if (res.type === "success") {
					setDocData(res.documents[0].documents)
					setUploadedDoc(res.uploaded)
				}
			});
	}


	const onButtonClick = (id) => {
		// Find the document based on the id
		console.log(uploadedDoc, "uploadedd")
		console.log(id, "uploadedd")

		const docData = uploadedDoc?.find(item => item._id === id);
		console.log(docData, "DOCDtraa")
		if (docData) {
			const pdfUrl = docData.file_url;  // This should be the full URL, e.g., 'https://...'
			console.log(docData, "docataa");

			const link = document.createElement("a");

			// If you want to extract the base URL (protocol + domain + port)
			const extractedUrl = pdfUrl.split('http://localhost:5000/')[1];
			// Set the link href to the full PDF URL
			link.href = extractedUrl;  // This should be the full URL, not just the base

			link.target = "_blank"; // Open in a new tab
			link.download = docData.file_name || "document.pdf"; // Set the download filename

			// Append the link to the DOM and trigger a click event
			document.body.appendChild(link);
			link.click(); // This will trigger the download or open the file
			document.body.removeChild(link); // Clean up the link element after clicking

		} else {
			console.log("Document not found!");
		}
	};


	useEffect(() => {
		if (show === 3) {
			getDocumentList();
		}
	}, [show])


	const handleRecognize = async (id) => {
		var submit_url = 'document/recognize-uploaded-document';
		await makeRequest(submit_url, { id: id }, 'POST', addError)
			.then((res) => {
				if (res.type === "success") {
					getDocumentList()
					dispatch(callCommonAction({ reloaddata: true }));
				}
			}).catch((error) => {

			});
	}

	const handleDeleteItem = (id) => {
		setSelectedItem(id);
		setDeleteItem(true);
	}


	return (
		<>
			{/* Step 3 START  */}
			<div className='container mb-5 border p-3'>
				<div className='row mb-4'>
					<div className='col cstm-top-heading pt-4'>
						<h6>{__trans('account_setup_step3_info')}</h6>
					</div>
					{request?.documents_template_id !== null ?
						<div className='col d-flex justify-content-end'>
							<div className='requestedFilesCompleted p-4 d-flex align-items-center gap-2'>
								<DocumentBlank width={'25px'} height={'25px'} color='#707BBD' />
								<h3>{uploadedDoc?.length}/{docData?.length}</h3>
								<p>{__trans('requested_files_completed')}</p>
							</div>
						</div>
						: ''
					}
				</div>
				<div className='row'>
					<div className="col">
						<div className='row'>
							{request?.documents_template_id !== null ?
								<>
									{/* Client Requested Document*/}
									<div className='col-md-12 mb-4'>
										<h5 className="mb-2">{__trans('client_setup_doc_list_title')}</h5>
										<Table aria-label="sample table" size="sm">
											<TableHead>
												<TableRow>
													<TableHeader>

													</TableHeader>
													<TableHeader>
														{__trans('client_setup_doc_table_header.file_name')}
													</TableHeader>
													<TableHeader>
														{__trans('DownloadDocument')}
													</TableHeader>
													<TableHeader>
														{__trans('client_setup_doc_table_header.created_at')}
													</TableHeader>
													<TableHeader>
														{__trans('client_setup_doc_table_header.description')}
													</TableHeader>

												</TableRow>
											</TableHead>
											<TableBody>
												{docData?.length && docData?.map((item, index) => (
													<TableRow key={item._id}>
														<TableCell>
															{index + 1}
														</TableCell>
														<TableCell>
															{item.name}
														</TableCell>
														<TableCell>
															{uploadedDoc
																.filter((items) => {
																	const baseNameItems = items.name.split('.').slice(0, -1).join('.').trim();  // Base name of uploaded doc
																	const baseNameItem = item.name
																	return baseNameItems == baseNameItem && items.document_ai_response >= 0.9900000;
																})
																.map((items) => (
																	<span className='d-flex align-items-center gap-1' key={items._id}>
																		<img
																			src={checkIcon}
																			alt="Checked"
																			style={{
																				width: '16px',
																				height: '16px',
																			}}
																		/>
																		{items.original_file_name}

																		<Button
																			size="xs"
																			kind="ghost"
																			renderIcon={Download}
																			iconDescription={__trans('download_button')}
																			hasIconOnly
																			onClick={() => onButtonClick(items?._id)}
																		/>

																	</span>

																))}

														</TableCell>


														<TableCell>
															{moment(item.updated_at).format('MM/DD/YYYY')}
														</TableCell>
														<TableCell>
															{item.description}
														</TableCell>

													</TableRow>
												))}
											</TableBody>
										</Table>
									</div>
									{/*Client Uploaded Document*/}
									{uploadedDoc?.length > 0 ?
										<div className='col-md-12 '>
											<h5 className="mb-2">{__trans('UnRecognize')}</h5>
											<Table aria-label="sample table" size="sm">
												<TableHead>
													<TableRow>
														<TableHeader>
															{__trans('client_setup_uploadeddoc_table_header.file_name')}
														</TableHeader>
														<TableHeader>
															{__trans('client_setup_uploadeddoc_table_header.description')}
														</TableHeader>
														<TableHeader>
															{__trans('client_setup_uploadeddoc_table_header.created_at')}
														</TableHeader>
														<TableHeader>
															{__trans('client_setup_uploadeddoc_table_header.created_at')}
														</TableHeader>
													</TableRow>
												</TableHead>
												<TableBody>
													{uploadedDoc?.length && uploadedDoc.filter(confidence => confidence?.document_ai_response <= 0.9900000).map((item, index) => (
														<TableRow key={item._id}>
															<TableCell>
																<div className='d-flex align-items-center gap-1'>
																<img
																	src={iconCross}
																	alt="Checked"
																	style={{
																		width: '16px',
																		height: '16px',
																	}}
																/>
																{item.original_file_name}
																</div>
															</TableCell>
															<TableCell>
																{item.description}
															</TableCell>
															<TableCell>
																{moment(item.created_at).format('MM/DD/YYYY')}
															</TableCell>
															<TableCell>
																<Button size="sm" kind="ghost" hasIconOnly renderIcon={Checkmark} onClick={() => handleRecognize(item._id)} iconDescription={__trans('Recognize')} />
																{item?.document_ai_response <= 0.9900000 && (
																	<Button size="sm" kind="ghost" renderIcon={Delete} onClick={() => handleDeleteItem(item._id)} iconDescription={__trans('delete_button')} hasIconOnly />
																)}

															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
											<DeleteConfirmation open={deleteitem} setOpen={setDeleteItem} module='uploaded-document' id={selecteditem} />

										</div>
										: ''
									}
								</>
								:
								<div className='text-center my-5'>
									<h5>{__trans('no_document_asked')}</h5>
								</div>
							}

						</div>
					</div>

				</div>
			</div>
		</>
	);
};

export default UploadDocument;