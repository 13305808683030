import React, { useState, useEffect } from 'react';
import moment from 'moment'
import { TrashCan as Delete, CloudUpload, ArrowRight, ArrowLeft, DocumentBlank, Download } from '@carbon/icons-react';
import { Button, FileUploaderButton, FileUploaderItem, RadioButton, Checkbox, Table, TableHead, TableBody, TableRow, TableHeader, TableCell } from 'carbon-components-react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import DeleteConfirmation from '../../../common/DeleteConfirmation';
import useToastAlert from '../../../../hooks/useToastAlert'
import { __trans, getData, makeRequest } from '../../../../_helpers';
import { callCommonAction } from '../../../../redux/Common/CommonReducer';
import checkIcon from './check.png'
import iconCross from './icon.png'
var lastId = 0;

const UploadDocument = ({ currentstep, handleNextPrevoius, requestdata }) => {
	const { addError } = useToastAlert();
	const { reloaddata, loading, contentLoading } = useSelector((state) => state.common);
	const dispatch = useDispatch();
	const [deletedocument, setDeleteDocument] = useState(false);
	const [template, setTemplate] = useState({});
	const [docData, setDocData] = useState({});
	const [radioerror, setRadioError] = useState('');
	const [fileerror, setFileError] = useState('');
	const [itemid, setItemId] = useState('');
	const [uploadedDoc, setUploadedDoc] = useState({});
	const [previewfiles, setPreviewFiles] = useState([]);
	const [uploaddata, setUploadData] = useState([]);
	const [documentData, setDocumentData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [isDataFetched, setIsDataFetched] = useState(false);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const code = queryParams.get('code');
	let stateUpdate




	const getDocumentList = async () => {
		await getData("template/get-client-template-document/" + requestdata._id, {}, 'POST', addError, dispatch)
			.then((res) => {
				if (res.type === "success") {
					setDocData(res.documents[0].documents)

					setUploadedDoc(res.uploaded)
					setTemplate(res.template)
				}
			});
	}



	useEffect(() => {
		if (currentstep === 2) {
			getDocumentList();
		}
	}, [currentstep])

	useEffect(() => {
		if (reloaddata) {
			getDocumentList();
			setItemId('')
		}
	}, [reloaddata])


	const handleDeleteDocument = (id) => {
		setItemId(id);
		setDeleteDocument(true);
		getDocumentList()
	}




	useEffect(() => {
		const fetchToken = async () => {

			if (code && !isDataFetched) {
				setIsLoading(true);

				try {
					const idsToSend = uploadedDoc?.map(doc => doc?._id);
					setIsLoading(true);
					const submit_url = `http://localhost:5000/api/v1/firm/processDocument?code=${code}`;
					const response = await axios.post(submit_url, idsToSend);
					console.log('Token received:', response);
					setDocumentData(response.data.document.entities)
					if (response.data) {
						getDocumentList()
						setIsLoading(false);
						setIsDataFetched(true)


					}

				} catch (error) {
					console.error('Error exchanging code:', error);
					setIsLoading(false);

				}
				finally {
					setIsLoading(false); // Ensure loading state is turned off
				}
			}
		};

		// Add a delay only if necessary
		const timeoutId = setTimeout(fetchToken, 4000);

		// Cleanup timeout
		return () => clearTimeout(timeoutId);
	}, [code, uploadedDoc, isDataFetched]); // Depend on `code`


	const uid = (prefix = 'id') => {
		lastId++;
		return `${prefix}${lastId}`;
	}

	const handleFileChange = async (event) => {
		console.log("sadasdasdasdasd", event.target.files.length)
		//clear previous selected file
		clearPreviousFiles();
		const files_object = Array.from(event.target.files); // Convert FileList to Array
		var new_upload_data = [...uploaddata];
		var new_files = [...previewfiles];

		var file = files_object;
		// Iterate through selected files
		for (const file of files_object) {

			// Uncomment and adjust validation logic as needed
			// if (file.size > 10 * 1000 * 1024) {
			//   setFileError(__trans('error_msg.file_size_limit_error'));
			//   return false;
			// }

			// if (!isValidFileUploaded(file)) {
			//   setFileError(__trans('error_msg.select_valid_file_type_error'));
			//   return false;
			// }

			// Update upload data
			new_upload_data.push(file);

			// Add file preview data
			new_files.push({
				uuid: uid(), // Function to generate unique ID
				name: file.name,
				filesize: file.size,
				status: 'edit',
				iconDescription: 'Delete file',
				invalidFileType: false // Set this based on your validation logic
			});
		}

		setFileError('');
		setUploadData(new_upload_data)
		setPreviewFiles(new_files);
	};

	const checkPrevoiusUploadedFile = async (event) => {

		if (event.target.value !== "") {
			event.preventDefault();
			this.form.validateFields(event.target.name);

			const fileObject = event.target.id;
			const name = event.target.files[0].name;
			const size = ((event.target.files[0].size) / 1000000).toFixed(2);
			let img = [...this.state.img];

			img.push({ inptid: fileObject, name: name, size: size });

			const fetchImage = img.map(e => e['inptid'])
				.map((e, i, final) => final.lastIndexOf(e) === i && i)
				.filter(e => img[e]).map(e => img[e]);

			this.setState({ img: fetchImage });

		} else {
			return false;
		}
	}

	const clearPreviousFiles = () => {
		setFileError('');
		setRadioError('');
		setItemId('');
		setUploadData([])
		setPreviewFiles([]);
	}

	const isValidFileUploaded = (file) => {
		const validExtensions = ['jpg', 'png', 'pdf'];
		const fileExtension = file.name.split('.').pop();
		return validExtensions.includes(fileExtension);
	}

	const handleRemoveFile = (index) => {
		// Remove the file from the upload files array
		const uploadFiles = [...uploaddata];
		uploadFiles.splice(index, 1);
		setUploadData(uploadFiles);
		// Remove the file from the preview files array
		const previewFiles = [...previewfiles];
		previewFiles.splice(index, 1);
		setPreviewFiles(previewFiles);
	};

	const handleRadioChanged = (e) => {
		console.log(e, "sdfsdfsd")
		setItemId(e);
		setRadioError('');
	}

	const validateForm = () => {
		let validateData = true;
		if (itemid === '') {
			setRadioError(__trans('error_msg.select_radio_for_refrence_file'));
			validateData = false;
		} else {
			setRadioError('');
		}
		if (uploaddata.length === 0) {
			setFileError(__trans('error_msg.select_valid_file_error'));
			validateData = false;
		} else {
			setFileError('');
		}

		return validateData;
	}



	const test = async (idsToSend) => {
		try {
			console.log(uploadedDoc, "uploadedDc")
			setIsLoading(true);

			const submit_url = 'http://localhost:5000/api/v1/firm/processDocument';
			setIsLoading(true);
			const response = await axios.post(submit_url, idsToSend, 'POST');
			if (response) {
				setIsLoading(false);

			}
			window.location.href = response.data.authUrl;


			console.log('Token received:', response);
		} catch (error) {
			setIsLoading(false);
			console.error('Error exchanging code:', error);
		}
	}



	const handleSubmit = (e) => {
		//Before submit validate form
		const formData = new FormData();
		if (uploaddata.length > 0) {
			uploaddata.forEach(item => { formData.append(`document`, item) });
		}
		formData.append(`request_id`, requestdata._id)
		formData.append(`template_id`, template._id)
		submitForm(formData)
	}

	const submitForm = async (formData) => {
		var submit_url = 'document/upload';
		setIsLoading(true);  // Start loading before submitting
		await makeRequest(submit_url, formData, 'POST', addError)
			.then((res) => {
				if (res.type === "success") {
					clearPreviousFiles();
					getDocumentList();
					const idsToSend = res?.data?.map(doc => doc?.file?._id); // Assuming _id exists in each document

					console.log(idsToSend, "Mapped _ids");
					test(idsToSend)

				}
			}).catch((error) => {
				addError('error', error.message)
			});
	}


	const onButtonClick = (id) => {
		// Find the document based on the id
		const docData = uploadedDoc?.find(item => item._id === id);

		if (docData) {
			const pdfUrl = docData.file_url;  // This should be the full URL, e.g., 'https://...'
			console.log(docData, "docataa");

			const link = document.createElement("a");

			// If you want to extract the base URL (protocol + domain + port)
			const extractedUrl = pdfUrl.split('http://localhost:5000/')[1];
			// Set the link href to the full PDF URL
			link.href = extractedUrl;  // This should be the full URL, not just the base

			link.target = "_blank"; // Open in a new tab
			link.download = docData.file_name || "document.pdf"; // Set the download filename

			// Append the link to the DOM and trigger a click event
			document.body.appendChild(link);
			link.click(); // This will trigger the download or open the file
			document.body.removeChild(link); // Clean up the link element after clicking

		} else {
			console.log("Document not found!");
		}
	};




	return (
		<>

			{/* Step 3 START  */}
			< div className='container mb-5'>
				{isLoading && (
					<div className="loader-container">
						<div className="loader"></div>  {/* Add your loader style or use a library component */}
					</div>
				)}
				<div className='row mb-4 justify-content-between'>

					<div className='col d-flex justify-content-start'>
						<div className='requestedFilesCompleted p-4 d-flex align-items-center gap-2'>
							<DocumentBlank width={'25px'} height={'25px'} color='#707BBD' />
							<h3>{uploadedDoc.length}/{docData.length}</h3>
							<p>{__trans('requested_files_completed')}</p>
						</div>
					</div>
					<div className='col cstm-top-heading pt-4 d-flex justify-content-end'>
						<h6>{__trans('account_setup_step3_info')}</h6>
					</div>
				</div>

				<div className='row'>
					<div className="col">
						<div className='row'>
							{/* Client Requested Document*/}
							<div className='col-md-12 mb-4'>
								<h5 className="mb-2">{__trans('client_setup_doc_list_title')}</h5>
								{radioerror !== '' ? <span className="text-danger">{radioerror}</span> : ''}
								<Table aria-label="sample table" size="sm">
									<TableHead>
										<TableRow>
											<TableHeader>

											</TableHeader>
											<TableHeader>
												{__trans('client_setup_doc_table_header.file_name')}
											</TableHeader>
											<TableHeader>
												{__trans('client_setup_doc_table_header.description')}
											</TableHeader>
											<TableHeader>
												{__trans('client_setup_doc_table_header.created_at')}
											</TableHeader>
											<TableHeader>
												{__trans('DownloadDocument')}
											</TableHeader>
										</TableRow>
									</TableHead>
									<TableBody>
										{console.log(uploadedDoc, "documentTRype")}
										{docData.length && docData.map((item, index) => (
											<TableRow key={item._id}>
												<TableCell>
													{code &&
														<div style={{ position: 'relative', display: 'inline-block' }}>
															{itemid === item._id || (uploadedDoc.find(d => d.name.split('.').slice(0, -1).join('.').toLowerCase().trim() === item.name && d.document_ai_response >= 0.9900000)) ? (
																// If condition is true, show the check icon
																<img
																	src={checkIcon}
																	alt="Checked"
																	style={{
																		width: '16px',  // Adjust size as needed
																		height: '16px', // Adjust size as needed
																		position: 'absolute',
																		top: '45%',
																		left: '50%',
																		transform: 'translate(-50%, -50%)',
																	}}
																/>
															) : (
																// If condition is false, show the cross icon
																<img
																	src={iconCross}
																	alt="Cross"
																	style={{
																		width: '16px',  // Adjust size as needed
																		height: '16px', // Adjust size as needed
																		position: 'absolute',
																		top: '50%',
																		left: '50%',
																		transform: 'translate(-50%, -50%)',
																	}}
																/>
															)}
														</div>
													}
												</TableCell>
												<TableCell>
													{item?.name}
												</TableCell>

												<TableCell>
													{item.description}
												</TableCell>
												<TableCell>
													{moment(item.updated_at).format('MM/DD/YYYY')}
												</TableCell>
												<TableCell>
													{uploadedDoc
														.filter((items) => {
															const baseNameItems = items.name.split('.').slice(0, -1).join('.').trim();  // Base name of uploaded doc
															const baseNameItem = item.name
															return baseNameItems == baseNameItem && items.document_ai_response >= 0.9900000;
														})
														.map((items) => (
															<span className='d-flex align-items-center gap-1' key={items._id}>

																{items.original_file_name}

																<Button
																	size="xs"
																	kind="ghost"
																	renderIcon={Download}
																	iconDescription={__trans('download_button')}
																	hasIconOnly
																	onClick={() => onButtonClick(items?._id)}
																/>

															</span>

														))}

												</TableCell>
												{/* <TableCell>
														<button onClick={() => onButtonClick(item._id)}>Download PDF</button>
													</TableCell> */}
											</TableRow>
										))}
									</TableBody>
								</Table>
							</div>
							{/*Client Uploaded Document*/}
							{uploadedDoc.length > 0 ?
								<div className='col-md-12 '>
									<h5 className="mb-2">{__trans('not_matching')}</h5>
									<Table aria-label="sample table" size="sm">
										<TableHead>
											<TableRow>
												<TableHeader>
													{__trans('client_setup_uploadeddoc_table_header.file_name')}
												</TableHeader>
												<TableHeader>
													{__trans('client_setup_uploadeddoc_table_header.description')}
												</TableHeader>
												<TableHeader>
													{__trans('client_setup_uploadeddoc_table_header.created_at')}
												</TableHeader>
												<TableHeader>
													{__trans('client_setup_uploadeddoc_table_header.actions')}
												</TableHeader>

											</TableRow>
										</TableHead>
										<TableBody>

											{uploadedDoc.length > 0 &&
												uploadedDoc.filter(items => items.document_ai_response <= 0.99000000).map((item, index) => (
													<TableRow key={item._id}>
														<TableCell>
															{item.original_file_name}
														</TableCell>
														<TableCell>
															{item.description}
														</TableCell>
														<TableCell>
															{moment(item.created_at).format('MM/DD/YYYY')}
														</TableCell>
														<TableCell>
															<Button
																size="sm"
																kind="ghost"
																renderIcon={Delete}
																iconDescription={__trans('download_button')}

																onClick={() => handleDeleteDocument(item._id)}

																hasIconOnly
															/>
															<Button
																size="sm"
																kind="ghost"
																renderIcon={Download}
																hasIconOnly
																onClick={() => onButtonClick(item._id)}
															/>
														</TableCell>
													</TableRow>
												))}
										</TableBody>

									</Table>
								</div>
								: ''
							}
						</div>

					</div>
					<div className='col d-flex justify-content-end'>
						<div className="">
							<div className='cloud-file-uploader d-flex align-items-center justify-content-center mb-3'>
								<div className='align-content-center d-flex flex-column justify-content-center'>
									<CloudUpload className='mx-auto mb-3' width={'85px'} height={'85px'} color='#4451A3' />
									<p className="cds--label-description" id="description" dir="auto">{__trans('client_upload_file_info')}</p>
									<div className="text-center mb-1">
										<FileUploaderButton className="btn--primary mb-2"
											labelText={__trans('choose_file_button')}
											disableLabelChanges={true}
											buttonKind="primary"
											size="md"
											type="file"
											webkitdirectory="true" // Allow folder uploads
											accept={['.jpg', '.png', '.pdf']}
											multiple={true}
											onChange={handleFileChange}
											style={{ paddingInlineEnd: '18px', }}
										/>

									</div>
									<div className="text-center mb-1">
										{fileerror !== '' ? <span className="text-danger mt-1">{fileerror}</span> : ''}
									</div>
									<div className="cds--file-container cds--file-container--drop fullWidth">
										{previewfiles.length > 0 && previewfiles.map((file, index) => (
											<FileUploaderItem
												className="bg-body-secondary"
												key={uid()}
												uuid={file.uuid}
												name={file.name}
												filesize={file.filesize}
												errorSubject={__trans('error_msg.file_size_limit_error')}
												errorBody=""
												webkitdirectory="true" // Allow folder uploads
												// eslint-disable-next-line react/prop-types
												size="md"
												status={file.status}
												iconDescription={file.iconDescription}
												invalid={file.invalid}
												onDelete={() => handleRemoveFile(index)}
											/>
										))}
									</div>
								</div>
							</div>


							<div className="text-center">
								<p className="text-center mb-3"><strong>Note:</strong>{__trans('select_file_for_info')}</p>
								<Button kind="primary" className="btn--primary" renderIcon={CloudUpload} size='md' onClick={handleSubmit}>Upload</Button>
							</div>
						</div>
					</div>



				</div>
			</div >
			{/* Delete Confirmation popup */}
			< DeleteConfirmation open={deletedocument} setOpen={setDeleteDocument} module='uploaded-document' id={itemid} />
			{console.log(uploadedDoc.length, "previewfiles")}
			<div className='container'>
				<div className='my-3 text-end'>
					<Button kind="secondary" renderIcon={ArrowLeft} size='md' onClick={() => handleNextPrevoius(currentstep - 1)}>Previous</Button>
					<Button className="btn--primary" renderIcon={ArrowRight} size='md' onClick={() => {
						// First, check if previewfiles is empty
						if (uploadedDoc.length === 0) {
							addError("Please select some files to upload");
						} else {
							handleNextPrevoius(currentstep + 1);
						}
						


					}}><span className='pe-3'>Next</span> </Button>
				</div>
			</div>
		</>
	);
};

export default UploadDocument;